import React from 'react';
import {
  Button,
  CircleImage,
  Icon,
  LottieAnimation,
  Picker,
  ScreenContainer,
  Shadow,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Spacer,
  Switch,
  SwitchRow,
  TabView,
  TabViewItem,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthApi from '../apis/AuthApi.js';
import * as ExampleDataApi from '../apis/ExampleDataApi.js';
import Animations from '../config/Animations';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import * as CustomCode_v2 from '../custom-files/CustomCode_v2';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';
import waitUtil from '../utils/wait';

const AdvertMonitorScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [isViewVisible_Payment, setIsViewVisible_Payment] =
    React.useState(false);
  const [isViewVisible_Success, setIsViewVisible_Success] =
    React.useState(false);
  const [isViewVisible_Voucher, setIsViewVisible_Voucher] =
    React.useState(true);
  const [pickerValue, setPickerValue] = React.useState('');
  const [progress, setProgress] = React.useState(33);
  const [switchRowValue, setSwitchRowValue] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const Auth_Me = (await AuthApi.authMeGET(Constants))?.json;
        const email_ = 'Auth_Me'?.email;
        const role_ = Auth_Me?.role;
        console.log('Advert Monitor: role check');
        if (role_ === 'advert' || 'admin') {
          return;
        }
        console.log('Advert Monitor: failed role check -> re-route');
        navigation.navigate('Restricted', { screen: 'RoutingPageScreen' });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={true}>
      {/* Main */}
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' } },
          dimensions.width
        )}
      >
        <Shadow
          offsetX={0}
          offsetY={0}
          paintInside={true}
          showShadowCornerBottomEnd={true}
          showShadowCornerBottomStart={true}
          showShadowCornerTopEnd={true}
          showShadowCornerTopStart={true}
          showShadowSideBottom={true}
          showShadowSideEnd={true}
          showShadowSideStart={true}
          showShadowSideTop={true}
          style={StyleSheet.applyWidth(
            {
              marginBottom: { minWidth: Breakpoints.Laptop, value: 30 },
              marginLeft: { minWidth: Breakpoints.Laptop, value: 30 },
              marginRight: { minWidth: Breakpoints.Laptop, value: 3 },
              marginTop: { minWidth: Breakpoints.Laptop, value: 30 },
              width: { minWidth: Breakpoints.Laptop, value: 300 },
            },
            dimensions.width
          )}
        >
          {/* Details */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.Brand.Surface,
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 5 },
                height: { minWidth: Breakpoints.Laptop, value: 200 },
                padding: { minWidth: Breakpoints.Laptop, value: 10 },
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Text'].style,
                dimensions.width
              )}
            >
              {'test'}
            </Text>
          </View>
        </Shadow>
        {/* Shadow 2 */}
        <Shadow
          offsetX={0}
          offsetY={0}
          paintInside={true}
          showShadowCornerBottomEnd={true}
          showShadowCornerBottomStart={true}
          showShadowCornerTopEnd={true}
          showShadowCornerTopStart={true}
          showShadowSideBottom={true}
          showShadowSideEnd={true}
          showShadowSideStart={true}
          showShadowSideTop={true}
          style={StyleSheet.applyWidth(
            {
              height: { minWidth: Breakpoints.Laptop, value: '100%' },
              marginBottom: { minWidth: Breakpoints.Laptop, value: 30 },
              marginLeft: { minWidth: Breakpoints.Laptop, value: 30 },
              marginRight: { minWidth: Breakpoints.Laptop, value: 3 },
              marginTop: { minWidth: Breakpoints.Laptop, value: 30 },
              width: { minWidth: Breakpoints.Laptop, value: 600 },
            },
            dimensions.width
          )}
        >
          {/* Main */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.Brand.Surface,
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 5 },
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
                padding: { minWidth: Breakpoints.Laptop, value: 10 },
              },
              dimensions.width
            )}
          >
            {/* Progress */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                },
                dimensions.width
              )}
            >
              <View>
                {/* Spacer 2 */}
                <Spacer bottom={8} left={8} right={8} top={8} />
                <Utils.CustomCodeErrorBoundary>
                  <CustomCode_v2.ProgressBarWithStages progress={progress} />
                </Utils.CustomCodeErrorBoundary>
                <Spacer bottom={8} left={8} right={8} top={8} />
                {/* Spacer 3 */}
                <Spacer bottom={8} left={8} right={8} top={8} />
              </View>
            </View>
            {/* Select Voucher */}
            <>
              {!isViewVisible_Voucher ? null : (
                <View>
                  {/* Target Group */}
                  <View>
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Laptop,
                            value: 'center',
                          },
                          flexDirection: {
                            minWidth: Breakpoints.Laptop,
                            value: 'row',
                          },
                          justifyContent: {
                            minWidth: Breakpoints.Laptop,
                            value: 'flex-start',
                          },
                          margin: { minWidth: Breakpoints.Laptop, value: 10 },
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'System',
                              },
                              fontWeight: {
                                minWidth: Breakpoints.Laptop,
                                value: '700',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Incentive'}
                      </Text>
                      <Spacer bottom={8} left={8} right={8} top={8} />
                      <Picker
                        autoDismissKeyboard={true}
                        dropDownBackgroundColor={theme.colors.background.brand}
                        dropDownBorderColor={theme.colors.border.brand}
                        dropDownBorderRadius={8}
                        dropDownBorderWidth={1}
                        dropDownTextColor={theme.colors.text.strong}
                        iconSize={24}
                        leftIconMode={'inset'}
                        mode={'native'}
                        onValueChange={newPickerValue => {
                          const pickerValue = newPickerValue;
                          try {
                            setPickerValue(newPickerValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Select an option'}
                        selectedIconColor={theme.colors.text.strong}
                        selectedIconName={'Feather/check'}
                        selectedIconSize={20}
                        type={'solid'}
                        value={pickerValue}
                      />
                      {/* Spacer 2 */}
                      <Spacer bottom={8} left={8} right={8} top={8} />
                    </View>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Laptop,
                            value: 'center',
                          },
                          flexDirection: {
                            minWidth: Breakpoints.Laptop,
                            value: 'row',
                          },
                          justifyContent: {
                            minWidth: Breakpoints.Laptop,
                            value: 'flex-start',
                          },
                          margin: { minWidth: Breakpoints.Laptop, value: 10 },
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'System',
                              },
                              fontWeight: {
                                minWidth: Breakpoints.Laptop,
                                value: '700',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Target Group'}
                      </Text>
                      <Spacer bottom={8} left={8} right={8} top={8} />
                      <Picker
                        autoDismissKeyboard={true}
                        dropDownBackgroundColor={theme.colors.background.brand}
                        dropDownBorderColor={theme.colors.border.brand}
                        dropDownBorderRadius={8}
                        dropDownBorderWidth={1}
                        dropDownTextColor={theme.colors.text.strong}
                        iconSize={24}
                        leftIconMode={'inset'}
                        mode={'native'}
                        onValueChange={newPickerValue => {
                          const pickerValue = newPickerValue;
                          try {
                            setPickerValue(newPickerValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Select an option'}
                        selectedIconColor={theme.colors.text.strong}
                        selectedIconName={'Feather/check'}
                        selectedIconSize={20}
                        type={'solid'}
                        value={pickerValue}
                      />
                      {/* Spacer 2 */}
                      <Spacer bottom={8} left={8} right={8} top={8} />
                    </View>
                  </View>
                </View>
              )}
            </>
            {/* Payment */}
            <>
              {!isViewVisible_Payment ? null : (
                <View
                  onLayout={event => {
                    const handler = async () => {
                      try {
                        await waitUtil({ milliseconds: 2000 });
                        setIsViewVisible_Payment(false);
                        setIsViewVisible_Success(true);
                        setProgress(100);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                    },
                    dimensions.width
                  )}
                >
                  <LottieAnimation
                    autoPlay={true}
                    {...GlobalStyles.LottieAnimationStyles(theme)[
                      'Lottie Animation'
                    ].props}
                    loop={false}
                    source={imageSource(Animations['animation1732656050378'])}
                    speed={0.75}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LottieAnimationStyles(theme)[
                          'Lottie Animation'
                        ].style,
                        {
                          height: { minWidth: Breakpoints.Laptop, value: 200 },
                          width: { minWidth: Breakpoints.Laptop, value: 200 },
                        }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
              )}
            </>
            {/* Success */}
            <>
              {!isViewVisible_Success ? null : (
                <View
                  onLayout={event => {
                    try {
                      setIsViewVisible_Payment(false);
                      setIsViewVisible_Success(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'System',
                          },
                          fontWeight: {
                            minWidth: Breakpoints.Laptop,
                            value: '700',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Success!! 🎉'}
                  </Text>
                </View>
              )}
            </>
            {/* Selection */}
            <>
              {!isViewVisible_Voucher ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: {
                        minWidth: Breakpoints.Laptop,
                        value: 'row',
                      },
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'flex-end',
                      },
                      margin: { minWidth: Breakpoints.Laptop, value: 10 },
                    },
                    dimensions.width
                  )}
                >
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setIsViewVisible_Voucher(false);
                        setIsViewVisible_Payment(true);
                        setProgress(66);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      dimensions.width
                    )}
                    title={'Weiter'}
                  />
                </View>
              )}
            </>
          </View>
        </Shadow>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AdvertMonitorScreen);
