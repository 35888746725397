import React, { useEffect, useState } from 'react';
import {
  View,
  StyleSheet,
  ActivityIndicator,
  Text as RNText,
  Dimensions,
} from 'react-native';
import Svg, { Rect, Text, Line } from 'react-native-svg';

const fetchHeatmapData = async () => {
  try {
    const response = await fetch(
      'https://x8ki-letl-twmt.n7.xano.io/api:1zczavK0/product_ratings',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

const groupDataByBrand = data => {
  const categories = [
    'Komfort',
    'Design',
    'Haltbarkeit',
    'Passform',
    'Material',
    'Wasserbestaendigkeit',
    'Reinigung',
    'Preis',
    'Atmungsaktivitaet',
  ];

  const groupedData = {};

  data.forEach(item => {
    if (!groupedData[item.Product_Brand]) {
      groupedData[item.Product_Brand] = {
        brand: item.Product_Brand,
        votes: categories.reduce((acc, category) => {
          acc[category] = [];
          return acc;
        }, {}),
      };
    }

    categories.forEach(category => {
      let value = item[category];

      if (typeof value === 'string') {
        value = value.toLowerCase() === 'true';
      } else {
        value = !!value;
      }

      groupedData[item.Product_Brand].votes[category].push(value);
    });
  });

  return Object.values(groupedData);
};

const calculateLikePercentage = votes => {
  const totalVotes = votes.length;
  const trueVotes = votes.filter(vote => {
    if (typeof vote === 'string') {
      return vote.toLowerCase() === 'true';
    }
    return !!vote;
  }).length;

  return totalVotes > 0 ? Math.round((trueVotes / totalVotes) * 100) : 0;
};

const getColorFromPercentage = percentage => {
  const red = Math.round((100 - percentage) * 2.55);
  const green = Math.round(percentage * 2.55);
  return `rgb(${red}, ${green}, 0)`;
};

export function Heatmap() {
  const [likeOrNotGroups, setLikeOrNotGroups] = useState({});
  const [loading, setLoading] = useState(true);
  const { width: screenWidth } = Dimensions.get('window');

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchHeatmapData();

      const groupedByLikeOrNot = data.reduce((acc, item) => {
        //const key = item.like_or_not || 'unknown';

        const key =
          item.like_or_not === true
            ? 'liked their shoes'
            : item.like_or_not === false
            ? 'did not like their shoes'
            : 'unknown';

        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {});

      const processedGroups = {};
      Object.entries(groupedByLikeOrNot).forEach(([key, group]) => {
        const groupedData = groupDataByBrand(group);
        const processedData = groupedData.map(brandData => {
          const votesWithPercentages = {};
          Object.keys(brandData.votes).forEach(category => {
            const percentage = calculateLikePercentage(
              brandData.votes[category]
            );
            votesWithPercentages[category] = percentage;
          });
          return {
            brand: brandData.brand,
            votes: votesWithPercentages,
          };
        });
        processedGroups[key] = processedData;
      });

      setLikeOrNotGroups(processedGroups);
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  const renderHeatmap = (data, title) => {
    const squareSize = 80;
    const gap = 4;
    const paddingX = 100;
    const paddingY = 140;
    const categories = [
      'Komfort',
      'Design',
      'Haltbarkeit',
      'Passform',
      'Material',
      'Wasserbestaendigkeit',
      'Reinigung',
      'Preis',
      'Atmungsaktivitaet',
    ];

    const width = paddingX + (squareSize + gap) * categories.length;
    const height = paddingY + (squareSize + gap) * data.length;

    return (
      <View key={title} style={styles.heatmapContainer}>
        <RNText style={styles.title}>{title}</RNText>
        <Svg height={height} width={screenWidth}>
          {categories.map((_, colIndex) => (
            <Line
              key={`v-line-${colIndex}`}
              x1={paddingX + colIndex * (squareSize + gap)}
              y1={paddingY}
              x2={paddingX + colIndex * (squareSize + gap)}
              y2={height}
              stroke="grey"
              strokeWidth={1}
              strokeDasharray="5,5"
            />
          ))}
          {data.map((_, rowIndex) => (
            <Line
              key={`h-line-${rowIndex}`}
              x1={paddingX}
              y1={paddingY + rowIndex * (squareSize + gap)}
              x2={width}
              y2={paddingY + rowIndex * (squareSize + gap)}
              stroke="grey"
              strokeWidth={1}
              strokeDasharray="5,5"
            />
          ))}

          {data.map((item, rowIndex) => (
            <Text
              key={item.brand}
              x={paddingX - 20}
              y={paddingY + rowIndex * (squareSize + gap) + squareSize / 2}
              fontSize={16}
              fill="black"
              textAnchor="end"
              alignmentBaseline="middle"
              fontWeight="bold"
            >
              {item.brand}
            </Text>
          ))}

          {categories.map((category, colIndex) => (
            <Text
              key={category}
              x={paddingX + colIndex * (squareSize + gap) + squareSize / 2}
              y={paddingY - 60}
              fontSize={14}
              fill="black"
              textAnchor="middle"
              alignmentBaseline="middle"
              fontWeight="bold"
              transform={`rotate(-45, ${
                paddingX + colIndex * (squareSize + gap) + squareSize / 2
              }, ${paddingY - 60})`}
            >
              {category}
            </Text>
          ))}

          {data.map((item, rowIndex) => {
            return categories.map((category, colIndex) => {
              const likePercentage = item.votes[category];
              const fillColor = getColorFromPercentage(likePercentage);

              return (
                <React.Fragment key={`${item.brand}-${category}`}>
                  <Rect
                    x={paddingX + colIndex * (squareSize + gap)}
                    y={paddingY + rowIndex * (squareSize + gap)}
                    width={squareSize}
                    height={squareSize}
                    fill={fillColor}
                    rx={10}
                  />
                  <Text
                    x={
                      paddingX + colIndex * (squareSize + gap) + squareSize / 2
                    }
                    y={
                      paddingY + rowIndex * (squareSize + gap) + squareSize / 2
                    }
                    fontSize={14}
                    fill="white"
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    fontWeight="bold"
                  >
                    {`${likePercentage}%`}
                  </Text>
                </React.Fragment>
              );
            });
          })}
        </Svg>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {Object.entries(likeOrNotGroups).map(([groupKey, groupData]) =>
        renderHeatmap(groupData, `Group: ${groupKey}`)
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  heatmapContainer: {
    marginBottom: 40,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginVertical: 10,
  },
});
