import React, { useEffect, useRef } from 'react';
import { StyleSheet, View, Text, Dimensions } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  interpolate,
  Extrapolate,
} from 'react-native-reanimated';

const { width, height } = Dimensions.get('window');

const sections = [
  {
    title: 'Impulse',
    content: 'B2B2C Supply Chain Feedback',
  },
  {
    title: 'Vision',
    content: 'Market Leader in Direct Consumer-to-Producer Feedback ',
    content_2: 'across B2B2C Supply Chains',
  },
  {
    title: 'Purpose',
    content: 'We help businesses to grow and innovate their portfolio',
    content_2: 'to drive sustainable economic growth',
  },
  {
    title: 'Our Products',
    content: 'Impulse Create & Analyze AI Platform for Enterprise',
    content_2: 'Impulse Advert for Local Businesses',
    content_3: 'Impulse ME for Consumers',
  },
  {
    title: 'Impulse',
    content: 'B2B2C Supply Chain Feedback',
  },
];

export function LandingPage() {
  const scrollX = useSharedValue(0);
  const scrollRef = useRef(null);
  const totalSections = sections.length;
  const direction = useRef(1); // 1 for forward, -1 for backward
  const isAutoScrolling = useRef(false);

  // Auto-scroll timing
  const AUTO_SCROLL_INTERVAL = 7500; // Time each section stays in view (ms)
  const AUTO_SCROLL_DURATION = 1900; // Time it takes to scroll to next section (ms)

  useEffect(() => {
    let isMounted = true;

    const autoScroll = () => {
      if (!isMounted || !scrollRef.current || isAutoScrolling.current) return;
      isAutoScrolling.current = true;

      // Calculate the next index based on the current scroll position and direction
      const pageWidth = width;
      const currentOffset = scrollX.value;
      const currentIndex = Math.round(currentOffset / pageWidth);

      let nextIndex = currentIndex + direction.current;

      if (nextIndex > totalSections - 1) {
        // Reverse direction at the end
        direction.current = -1;
        nextIndex = totalSections - 2;
      } else if (nextIndex < 0) {
        // Reverse direction at the beginning
        direction.current = 1;
        nextIndex = 1;
      }

      const nextOffset = nextIndex * pageWidth;

      scrollRef.current.scrollTo({
        x: nextOffset,
        animated: true,
      });

      // Wait for the scroll animation to finish before allowing another auto-scroll
      setTimeout(() => {
        isAutoScrolling.current = false;
      }, AUTO_SCROLL_DURATION);
    };

    const intervalId = setInterval(autoScroll, AUTO_SCROLL_INTERVAL);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, []);

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: event => {
      scrollX.value = event.contentOffset.x;
    },
  });

  return (
    <View style={styles.container}>
      {/* Horizontal ScrollView */}
      <Animated.ScrollView
        ref={scrollRef}
        horizontal
        pagingEnabled
        onScroll={scrollHandler}
        scrollEventThrottle={16}
        showsHorizontalScrollIndicator={false}
        decelerationRate="fast"
        overScrollMode="never"
      >
        {sections.map((section, index) => (
          <SectionContent
            key={index}
            index={index}
            title={section.title}
            content={section.content}
            content_2={section.content_2}
            content_3={section.content_3}
            scrollX={scrollX}
            totalSections={totalSections}
          />
        ))}
      </Animated.ScrollView>

      {/* Collapsed Sections on the Left */}
      <View style={styles.leftAccordion}>
        {sections.map((section, index) => (
          <CollapsedTitle
            key={index}
            index={index}
            title={section.title}
            scrollX={scrollX}
            totalSections={totalSections}
          />
        ))}
      </View>
    </View>
  );
}

const CollapsedTitle = ({ index, title, scrollX, totalSections }) => {
  const position = index * width;

  const animatedStyle = useAnimatedStyle(() => {
    const opacity = interpolate(
      scrollX.value,
      [position, position + width],
      [0, 1],
      Extrapolate.CLAMP
    );

    const translateY = interpolate(
      scrollX.value,
      [position, position + width],
      [-50, 0],
      Extrapolate.CLAMP
    );

    return {
      opacity,
      transform: [{ translateY }],
    };
  });

  return (
    <Animated.View style={[styles.collapsedTitle, animatedStyle]}>
      <Text style={styles.titleText}>{title}</Text>
    </Animated.View>
  );
};

const SectionContent = ({
  index,
  title,
  content,
  content_2,
  content_3,
  scrollX,
  totalSections,
}) => {
  const position = index * width;

  const animatedStyle = useAnimatedStyle(() => {
    const scale = interpolate(
      scrollX.value,
      [position - width, position, position + width],
      [0.9, 1, 0.9],
      Extrapolate.CLAMP
    );

    const opacity = interpolate(
      scrollX.value,
      [position - width, position, position + width],
      [0.7, 1, 0.7],
      Extrapolate.CLAMP
    );

    return {
      transform: [{ scale }],
      opacity,
    };
  });

  return (
    <Animated.View style={[styles.sectionContent, animatedStyle]}>
      <Text style={styles.sectionTitle}>{title}</Text>
      <Text style={styles.sectionText}>{content}</Text>
      <Text style={styles.sectionText}>{content_2}</Text>
      <Text style={styles.sectionText}>{content_3}</Text>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#111', // Dark background
  },
  // Removed rightAccordion styles

  leftAccordion: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: width * 0.2,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 10,
    backgroundColor: '#070707',
  },
  collapsedTitle: {
    marginVertical: 10,
  },
  titleText: {
    color: '#fff',
    fontSize: 18,
  },
  sectionContent: {
    width: width,
    height: (height / 8) * 5, // Full screen height
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: width * 0.2, // Adjusted padding to account for left accordion
    paddingRight: 20, // Optional: add some right padding
    backgroundColor: '#fff', // White background
  },
  sectionTitle: {
    fontSize: 38,
    fontWeight: 'bold',
    color: '#000', // Black text
    marginBottom: 10,
    textAlign: 'center',
  },
  sectionText: {
    fontSize: 20,
    color: '#000', // Black text
    textAlign: 'center',
    marginBottom: 5,
  },
});
