import React from 'react';
import { LottieAnimation, ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthApi from '../apis/AuthApi.js';
import Animations from '../config/Animations';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const RoutingPageScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const Auth_Request = (await AuthApi.authMeGET(Constants))?.json;
        const email = Auth_Request?.email;
        const role = Auth_Request?.role;
        if (role) {
          if (role === 'advert') {
            console.log('nav: advert');
            navigation.navigate('Advert');
          } else {
            if (role === 'analyst') {
              console.log('nav: analyst');
              navigation.navigate('CreateAndAnalyze');
            } else {
              if (role === 'admin') {
                console.log('nav: admin');
                navigation.navigate('Restricted', { screen: 'AdminScreen' });
              } else {
                navigation.navigate('Public', { screen: 'LoginScreen' });
              }
            }
          }
        } else {
          navigation.navigate('Public', { screen: 'LoginScreen' });
          /* hidden 'Show Alert' action */
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
            alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
            flex: { minWidth: Breakpoints.Laptop, value: 1 },
            height: { minWidth: Breakpoints.Laptop, value: 100 },
            justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
            width: { minWidth: Breakpoints.Laptop, value: '100%' },
          },
          dimensions.width
        )}
      >
        <View>
          <LottieAnimation
            autoPlay={true}
            loop={true}
            {...GlobalStyles.LottieAnimationStyles(theme)['Lottie Animation']
              .props}
            source={imageSource(Animations['animation1732716370291'])}
            speed={0.7}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.LottieAnimationStyles(theme)['Lottie Animation']
                  .style,
                {
                  height: { minWidth: Breakpoints.Laptop, value: 200 },
                  width: { minWidth: Breakpoints.Laptop, value: 200 },
                }
              ),
              dimensions.width
            )}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(RoutingPageScreen);
