import React from 'react';
import { Link, Spacer, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const MenuBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignItems: [
            { minWidth: Breakpoints.Mobile, value: 'center' },
            { minWidth: Breakpoints.Laptop, value: 'flex-start' },
          ],
          alignSelf: 'stretch',
          backgroundColor: palettes.App['Custom Color 4'],
          borderWidth: { minWidth: Breakpoints.Laptop, value: 0 },
          justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
        },
        dimensions.width
      )}
    >
      <Spacer bottom={8} left={8} right={8} top={8} />
      {/* Links */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: 'flex-start',
            alignItems: [
              { minWidth: Breakpoints.Laptop, value: 'center' },
              { minWidth: Breakpoints.Mobile, value: 'center' },
            ],
            alignSelf: 'center',
            backgroundColor: {
              minWidth: Breakpoints.Laptop,
              value: palettes.App['Custom Color'],
            },
            borderRadius: { minWidth: Breakpoints.Laptop, value: 2 },
            flexDirection: [
              { minWidth: Breakpoints.Laptop, value: 'row' },
              { minWidth: Breakpoints.Mobile, value: 'row' },
            ],
            height: { minWidth: Breakpoints.Laptop, value: 30 },
            justifyContent: [
              { minWidth: Breakpoints.Laptop, value: 'center' },
              { minWidth: Breakpoints.Mobile, value: 'space-around' },
            ],
            marginLeft: { minWidth: Breakpoints.Laptop, value: 40 },
            width: { minWidth: Breakpoints.Laptop, value: 500 },
          },
          dimensions.width
        )}
      >
        {/* Login */}
        <Link
          accessible={true}
          onPress={() => {
            try {
              navigation.navigate('Public', { screen: 'LandingPageScreen' });
            } catch (err) {
              console.error(err);
            }
          }}
          selectable={false}
          {...GlobalStyles.LinkStyles(theme)['Link'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'].style, {
              color: [
                {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors.text.strong,
                },
                {
                  minWidth: Breakpoints.Mobile,
                  value: palettes.App['Custom Color'],
                },
              ],
              fontFamily: { minWidth: Breakpoints.Laptop, value: 'System' },
              fontSize: { minWidth: Breakpoints.Laptop, value: 17 },
              fontWeight: { minWidth: Breakpoints.Laptop, value: '300' },
              marginLeft: [
                { minWidth: Breakpoints.Mobile, value: 10 },
                { minWidth: Breakpoints.Laptop, value: 0 },
              ],
            }),
            dimensions.width
          )}
          title={'Impulse'}
        />
        {/* Spacer 2 */}
        <Spacer bottom={8} top={8} left={20} right={20} />
        {/* Advert */}
        <Link
          accessible={true}
          onPress={() => {
            try {
              navigation.navigate('Public', {
                screen: 'ImpulseAdvertRegistrationScreen',
              });
            } catch (err) {
              console.error(err);
            }
          }}
          selectable={false}
          {...GlobalStyles.LinkStyles(theme)['Link'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'].style, {
              color: [
                {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors.text.strong,
                },
                {
                  minWidth: Breakpoints.Mobile,
                  value: palettes.App['Custom Color'],
                },
              ],
              fontFamily: { minWidth: Breakpoints.Laptop, value: 'System' },
              fontSize: { minWidth: Breakpoints.Laptop, value: 17 },
              fontWeight: { minWidth: Breakpoints.Laptop, value: '300' },
            }),
            dimensions.width
          )}
          title={'Advert Registration'}
        />
        {/* Spacer 3 */}
        <Spacer bottom={8} top={8} left={20} right={20} />
        {/* Login 2 */}
        <Link
          accessible={true}
          onPress={() => {
            try {
              navigation.navigate('Public', { screen: 'LoginScreen' });
            } catch (err) {
              console.error(err);
            }
          }}
          selectable={false}
          {...GlobalStyles.LinkStyles(theme)['Link'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'].style, {
              color: [
                {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors.text.strong,
                },
                {
                  minWidth: Breakpoints.Mobile,
                  value: palettes.App['Custom Color'],
                },
              ],
              fontFamily: { minWidth: Breakpoints.Laptop, value: 'System' },
              fontSize: { minWidth: Breakpoints.Laptop, value: 17 },
              fontWeight: { minWidth: Breakpoints.Laptop, value: '300' },
            }),
            dimensions.width
          )}
          title={'Login'}
        />
        <Spacer bottom={8} top={8} left={20} right={20} />
        {/* Impulse App */}
        <Link
          accessible={true}
          onPress={() => {
            const handler = async () => {
              try {
                await WebBrowser.openBrowserAsync(
                  'https://r6tgw47jsy.draftbit.app/'
                );
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          selectable={false}
          {...GlobalStyles.LinkStyles(theme)['Link'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'].style, {
              color: [
                {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors.text.strong,
                },
                {
                  minWidth: Breakpoints.Mobile,
                  value: palettes.App['Custom Color'],
                },
              ],
              fontFamily: { minWidth: Breakpoints.Laptop, value: 'System' },
              fontSize: { minWidth: Breakpoints.Laptop, value: 17 },
              fontWeight: { minWidth: Breakpoints.Laptop, value: '300' },
            }),
            dimensions.width
          )}
          title={'Impulse App'}
        />
      </View>
      <Spacer left={8} right={8} bottom={10} />
    </View>
  );
};

export default withTheme(MenuBlock);
