import React from 'react';
import { Button, ScreenContainer, Spacer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthApi from '../apis/AuthApi.js';
import * as ProductRatingsApi from '../apis/ProductRatingsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Archive from '../custom-files/Archive';
import * as Charts from '../custom-files/Charts';
import * as CustomCode from '../custom-files/CustomCode';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const DashboardScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const CheckAuth = (await AuthApi.authMeGET(Constants))?.json;
        const role = CheckAuth?.role;
        console.log('Dashboard: role check');
        if (role === 'admin' || 'analyst') {
          return;
        }
        console.log('Dashboard: failed role check -> re-route');
        navigation.navigate('Restricted', { screen: 'RoutingPageScreen' });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasBottomSafeArea={true}
      scrollable={true}
    >
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: { minWidth: Breakpoints.Laptop, value: 'column' } },
          dimensions.width
        )}
      >
        {/* Custom Code 4 */}
        <Utils.CustomCodeErrorBoundary>
          <Charts.Heatmap />
        </Utils.CustomCodeErrorBoundary>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(DashboardScreen);
