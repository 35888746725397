import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

export const ProgressBarWithStages = ({ progress = 0, stages = 3 }) => {
  // Calculate active stages based on progress (0-100 range)
  const activeStages = useMemo(
    () => Math.min(stages, Math.ceil((progress / 100) * stages)),
    [progress, stages]
  );

  const renderStages = () => {
    return Array.from({ length: stages }).map((_, index) => {
      const isActive = index < activeStages;
      const isLast = index === stages - 1;
      const isNextActive = index + 1 < stages && index + 1 < activeStages; // Check if the next stage is active

      return (
        <View key={index} style={styles.stageContainer}>
          {/* Circle */}
          <View style={[styles.circle, isActive && styles.circleActive]}>
            <Text
              style={[styles.circleText, isActive && styles.circleTextActive]}
            >
              {index + 1}
            </Text>
          </View>

          {/* Connector Line */}
          {!isLast && (
            <View
              style={[
                styles.line,
                isActive && isNextActive && styles.lineActive, // Apply active line style only if both current and next are active
              ]}
            />
          )}
        </View>
      );
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.progressBar}>{renderStages()}</View>
    </View>
  );
};

// Styles
const styles = StyleSheet.create({
  container: {
    flex: 1, // Use flex to make the container take the full height
    justifyContent: 'center', // Centers the progress bar vertically
    alignItems: 'center', // Centers the progress bar horizontally
    width: '100%',
  },
  progressBar: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 20,
  },
  stageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  circle: {
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: '#e0e0de',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleActive: {
    backgroundColor: '#3b5998',
  },
  circleText: {
    color: '#a0a0a0',
    fontWeight: 'bold',
    fontSize: 18,
  },
  circleTextActive: {
    color: '#ffffff',
  },
  line: {
    height: 4,
    backgroundColor: '#e0e0de',
    width: 50,
    marginLeft: 5, // Consistent margin for all lines
    marginRight: 5,
  },
  lineActive: {
    backgroundColor: '#3b5998',
  },
});
