import React from 'react';
import {
  Button,
  MultiSelectPicker,
  ScreenContainer,
  Spacer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';
import * as AuthApi from '../apis/AuthApi.js';
import FooterBlock from '../components/FooterBlock';
import MenuBlock from '../components/MenuBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const ImpulseAdvertRegistrationScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [companyInputValue, setCompanyInputValue] = React.useState('');
  const [emailInputValue, setEmailInputValue] = React.useState('');
  const [industryInput2Value, setIndustryInput2Value] = React.useState('');
  const [multiSelectPicker2Value, setMultiSelectPicker2Value] = React.useState(
    []
  );
  const [nameInputValue, setNameInputValue] = React.useState('');
  const [passwordInputValue, setPasswordInputValue] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState(undefined);
  const authSignUpPOST = AuthApi.useSignUpPOST();

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Laptop,
            value: palettes.App['Custom Color 4'],
          },
        },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: {
              minWidth: Breakpoints.Laptop,
              value: palettes.App['Custom Color 4'],
            },
          },
          dimensions.width
        )}
      >
        {/* View 2 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              flex: { minWidth: Breakpoints.Laptop, value: 1 },
            },
            dimensions.width
          )}
        >
          <MenuBlock />
        </View>
        {/* Top */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
        >
          {/* Title_ */}
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                color: {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors.background.brand,
                },
                fontFamily: [
                  { minWidth: Breakpoints.Mobile, value: 'System' },
                  { minWidth: Breakpoints.Laptop, value: 'System' },
                ],
                fontSize: [
                  { minWidth: Breakpoints.Mobile, value: 36 },
                  { minWidth: Breakpoints.Laptop, value: 60 },
                ],
                fontWeight: [
                  { minWidth: Breakpoints.Mobile, value: '600' },
                  { minWidth: Breakpoints.Laptop, value: '700' },
                ],
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Impulse Advert'}
          </Text>
          {/* Subtitle */}
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                color: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: theme.colors.text.strong,
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors.background.brand,
                  },
                ],
                fontFamily: [
                  { minWidth: Breakpoints.Mobile, value: 'System' },
                  { minWidth: Breakpoints.Laptop, value: 'System' },
                ],
                fontSize: { minWidth: Breakpoints.Laptop, value: 25 },
                fontWeight: [
                  { minWidth: Breakpoints.Mobile, value: '400' },
                  { minWidth: Breakpoints.Laptop, value: '200' },
                ],
                marginTop: 4,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Gain new customers for your local business'}
          </Text>
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
              marginBottom: { minWidth: Breakpoints.Laptop, value: 100 },
            },
            dimensions.width
          )}
        >
          {/* Main */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App['Custom Color'],
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 2 },
                margin: { minWidth: Breakpoints.Laptop, value: 40 },
                opacity: { minWidth: Breakpoints.Laptop, value: 1 },
                width: { minWidth: Breakpoints.Laptop, value: '80%' },
              },
              dimensions.width
            )}
          >
            {/* Register Form */}
            <View
              style={StyleSheet.applyWidth(
                { marginTop: 24, paddingLeft: 36, paddingRight: 36 },
                dimensions.width
              )}
            >
              {/* Error Message */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.background.danger,
                    fontSize: 12,
                    marginBottom: 16,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {null}
              </Text>
              {/* Name Input */}
              <TextInput
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newNameInputValue => {
                  try {
                    setNameInputValue(newNameInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                autoCapitalize={'words'}
                placeholder={'Name'}
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRadius: 2,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    color: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    fontFamily: 'System',
                    fontWeight: '400',
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                value={nameInputValue}
              />
              <Spacer left={8} right={8} bottom={12} top={12} />
              {/* Industry Input 2 */}
              <TextInput
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newIndustryInput2Value => {
                  try {
                    setIndustryInput2Value(newIndustryInput2Value);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                autoCapitalize={'words'}
                placeholder={'Industrie' ?? 'Name'}
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRadius: 2,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    color: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    fontFamily: 'System',
                    fontWeight: '400',
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                value={industryInput2Value}
              />
              {/* Spacer 4 */}
              <Spacer left={8} right={8} bottom={12} top={12} />
              {/* Company Input */}
              <TextInput
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newCompanyInputValue => {
                  try {
                    setCompanyInputValue(newCompanyInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                autoCapitalize={'words'}
                placeholder={'Unternehmen' ?? 'Name'}
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRadius: 2,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    color: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    fontFamily: 'System',
                    fontWeight: '400',
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                value={companyInputValue}
              />
              {/* Spacer 2 */}
              <Spacer left={8} right={8} bottom={12} top={12} />
              {/* Multi Select Picker 2 */}
              <MultiSelectPicker
                autoDismissKeyboard={true}
                dropDownBackgroundColor={theme.colors.background.brand}
                dropDownBorderRadius={8}
                dropDownBorderWidth={1}
                iconSize={24}
                leftIconMode={'inset'}
                onValueChange={newMultiSelectPicker2Value => {
                  const pickerValue = newMultiSelectPicker2Value;
                  try {
                    setMultiSelectPicker2Value(newMultiSelectPicker2Value);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                selectedIconName={'Feather/check'}
                selectedIconSize={20}
                type={'solid'}
                dropDownBorderColor={palettes.App['Custom Color 4']}
                dropDownTextColor={StyleSheet.getWidthValue(
                  [
                    {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.App['Custom Color 4'],
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App['Custom Color 4'],
                    },
                  ],
                  dimensions.width
                )}
                label={'Standorte'}
                options={Constants['cities']}
                placeholder={'Bitte wählen Sie Ihre Standorte aus'}
                placeholderTextColor={StyleSheet.getWidthValue(
                  [
                    {
                      minWidth: Breakpoints.Laptop,
                      value: theme.colors.text.strong,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App['Custom Color 4'],
                    },
                  ],
                  dimensions.width
                )}
                selectedIconColor={palettes.App['Custom Color 4']}
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: theme.colors.text.strong,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    borderRadius: 2,
                    color: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    fontFamily: 'System',
                    fontWeight: '400',
                  },
                  dimensions.width
                )}
                value={multiSelectPicker2Value}
              />
              {/* Spacer 3 */}
              <Spacer left={8} right={8} bottom={12} top={12} />
              {/* Email Input */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newEmailInputValue => {
                  try {
                    setEmailInputValue(newEmailInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                keyboardType={'email-address'}
                placeholder={'E-Mail' ?? 'Email'}
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRadius: 2,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    color: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    fontFamily: 'System',
                    fontWeight: '400',
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                textContentType={'emailAddress'}
                value={emailInputValue}
              />
              <Spacer bottom={8} left={8} right={8} top={12} />
              {/* Password Input */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newPasswordInputValue => {
                  try {
                    setPasswordInputValue(newPasswordInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                placeholder={'Passwort' ?? 'Password'}
                secureTextEntry={true}
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRadius: 2,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    color: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    fontFamily: 'System',
                    fontWeight: '400',
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                textContentType={'password'}
                value={passwordInputValue}
              />
              <Spacer left={8} right={8} bottom={24} top={24} />
              {/* Sign Up Button */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      console.log(
                        nameInputValue,
                        emailInputValue,
                        multiSelectPicker2Value,
                        industryInput2Value,
                        passwordInputValue,
                        companyInputValue
                      );
                      (
                        await authSignUpPOST.mutateAsync({
                          company: companyInputValue,
                          industry: industryInput2Value,
                          locations: multiSelectPicker2Value,
                          signupEmail: emailInputValue,
                          signupName: nameInputValue,
                          signupPassword: passwordInputValue,
                        })
                      )?.json;
                      navigation.navigate('Public', { screen: 'LoginScreen' });
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    borderRadius: 2,
                    fontFamily: 'System',
                    fontWeight: '700',
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Registrieren'}
              >
                {'Sign Up'}
              </Button>
              <Spacer left={8} right={8} bottom={16} top={16} />
            </View>
          </View>
          <Spacer bottom={8} left={8} right={8} top={15} />
        </View>
        {/* View 3 */}
        <View
          style={StyleSheet.applyWidth(
            {
              bottom: { minWidth: Breakpoints.Laptop, value: 0 },
              left: { minWidth: Breakpoints.Laptop, value: 0 },
              position: { minWidth: Breakpoints.Laptop, value: 'absolute' },
              right: { minWidth: Breakpoints.Laptop, value: 0 },
            },
            dimensions.width
          )}
        >
          <FooterBlock />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ImpulseAdvertRegistrationScreen);
