// This import is required if you are defining react components in this module.
import React, { useState } from 'react';

import { BarChart, Grid, XAxis } from 'react-native-svg-charts';

// Add any other imports you need here. Make sure to add those imports (besides "react"
// and "react-native") to the Packages section.
import {
  View,
  TextInput,
  Button,
  Text,
  ScrollView,
  StyleSheet,
} from 'react-native';
import axios from 'axios';

// Define and export your components as named exports here.

// You can use components exported from this file within a Custom Code component as
// <CustomCode.MyExampleComponent />
//export const MyExampleComponent = () => <Text>Hello world!</Text>;

export function BarChartComponent() {
  const fill = 'rgb(120, 50, 120)';
  const data = [5, 3, 4, 4, 4, 2, 1];

  return (
    <View style={{ height: 200, padding: 20 }}>
      <BarChart
        style={{ height: 200 }}
        data={data}
        svg={{ fill }}
        contentInset={{ top: 30, bottom: 30 }}
      >
        <Grid />
      </BarChart>
      <XAxis
        style={{ marginHorizontal: -10 }}
        data={data}
        formatLabel={(value, index) => index + 1}
        contentInset={{ left: 10, right: 10 }}
        svg={{ fontSize: 14, fill: 'black' }}
      />
    </View>
  );
}

export function ChatGPTComponent() {
  const [inputText, setInputText] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const sendMessage = async () => {
    if (!inputText.trim()) return;
    const newMessage = { role: 'user', content: inputText };
    const updatedChatHistory = [...chatHistory, newMessage];
    setChatHistory(updatedChatHistory);
    setInputText('');
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/engines/davinci-codex/completions',
        {
          prompt: inputText,
          max_tokens: 150,
          n: 1,
          stop: null,
          temperature: 0.7,
        },
        {
          headers: {
            Authorization: `Bearer YOUR_OPENAI_API_KEY`,
            'Content-Type': 'application/json',
          },
        }
      );
      const botMessage = {
        role: 'bot',
        content: response.data.choices[0].text.trim(),
      };
      setChatHistory([...updatedChatHistory, botMessage]);
    } catch (error) {
      console.error('Error fetching response from ChatGPT:', error);
    }
  };
  return (
    <View style={styles.container}>
      {' '}
      <ScrollView style={styles.chatHistory}>
        {' '}
        {chatHistory.map((message, index) => (
          <Text
            key={index}
            style={
              message.role === 'user' ? styles.userMessage : styles.botMessage
            }
          >
            {' '}
            {message.content}{' '}
          </Text>
        ))}{' '}
      </ScrollView>{' '}
      <TextInput
        style={styles.input}
        value={inputText}
        onChangeText={setInputText}
        placeholder="Type your message..."
      />{' '}
      <Button title="Send" onPress={sendMessage} />{' '}
    </View>
  );
}
const styles = StyleSheet.create({
  container: { flex: 1, padding: 10 },
  chatHistory: {
    height: 300,
    // Fixed height for the chat window
    paddingHorizontal: 10,
    marginBottom: 10,
    borderColor: 'gray',
    borderWidth: 1,
  },
  userMessage: { textAlign: 'right', color: 'blue', marginBottom: 10 },
  botMessage: { textAlign: 'left', color: 'black', marginBottom: 10 },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
});
