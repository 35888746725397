import React from 'react';
import {
  Button,
  CircleImage,
  Icon,
  LottieAnimation,
  Picker,
  ScreenContainer,
  Shadow,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Spacer,
  Switch,
  SwitchRow,
  TabView,
  TabViewItem,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthApi from '../apis/AuthApi.js';
import * as ExampleDataApi from '../apis/ExampleDataApi.js';
import Animations from '../config/Animations';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import * as CustomCode_v2 from '../custom-files/CustomCode_v2';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';
import waitUtil from '../utils/wait';

const RedeemVouchersScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [isViewVisible_Payment, setIsViewVisible_Payment] =
    React.useState(false);
  const [isViewVisible_Success, setIsViewVisible_Success] =
    React.useState(false);
  const [isViewVisible_Voucher, setIsViewVisible_Voucher] =
    React.useState(true);
  const [pickerValue, setPickerValue] = React.useState('');
  const [progress, setProgress] = React.useState(33);
  const [switchRowValue, setSwitchRowValue] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const Auth_Me = (await AuthApi.authMeGET(Constants))?.json;
        const email_ = 'Auth_Me'?.email;
        const role_ = Auth_Me?.role;
        console.log('Advert Monitor: role check');
        if (role_ === 'advert' || 'admin') {
          return;
        }
        console.log('Advert Monitor: failed role check -> re-route');
        navigation.navigate('Restricted', { screen: 'RoutingPageScreen' });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'stretch' },
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.Gray[300],
              },
            },
            dimensions.width
          )}
        >
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App['Custom #ffffff'],
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 5 },
                margin: { minWidth: Breakpoints.Laptop, value: 20 },
              },
              dimensions.width
            )}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  margin: { minWidth: Breakpoints.Laptop, value: 10 },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { marginRight: { minWidth: Breakpoints.Laptop, value: 5 } }
                  ),
                  dimensions.width
                )}
              >
                {'Redeem Voucher'}
              </Text>
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputValue => {
                  const textInputValue = newTextInputValue;
                  try {
                    setTextInputValue2(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Enter a value...'}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  dimensions.width
                )}
                value={textInputValue2}
              />
            </View>

            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  margin: { minWidth: Breakpoints.Laptop, value: 10 },
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { marginRight: { minWidth: Breakpoints.Laptop, value: 5 } }
                  ),
                  dimensions.width
                )}
              >
                {'Show Redeemed Vouchers'}
              </Text>
              <Switch
                onValueChange={newSwitchValue => {
                  const switchValue = newSwitchValue;
                  try {
                    setSwitchValue(newSwitchValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                value={switchValue}
              />
            </View>
          </View>
        </View>
        {/* Header Frame for Table */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
            },
            dimensions.width
          )}
        >
          {/* Table Header Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 0, flexShrink: 0, minWidth: 190, width: 190 },
              dimensions.width
            )}
          >
            {/* Column Title */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: 'theme.colors.custom_rgb16_16_16',
                  fontFamily: 'System',
                  fontSize: 14,
                  fontWeight: '600',
                  lineHeight: 14,
                  paddingBottom: 22,
                  paddingTop: 22,
                },
                dimensions.width
              )}
            >
              {'Type'}
            </Text>
          </View>
          {/* Table Title Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 0, flexShrink: 0, width: 190 },
              dimensions.width
            )}
          >
            {/* Column Title */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: 'theme.colors.custom_rgb16_16_16',
                  fontFamily: 'System',
                  fontSize: 14,
                  fontWeight: '600',
                  lineHeight: 14,
                  paddingBottom: 22,
                  paddingTop: 22,
                },
                dimensions.width
              )}
            >
              {'Incentive'}
            </Text>
          </View>
          {/* Table Title Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 0, flexShrink: 0, width: 190 },
              dimensions.width
            )}
          >
            {/* Column Title */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: 'theme.colors.custom_rgb16_16_16',
                  fontFamily: 'System',
                  fontSize: 14,
                  fontWeight: '600',
                  lineHeight: 14,
                  paddingBottom: 22,
                  paddingTop: 22,
                },
                dimensions.width
              )}
            >
              {'Percentage Change'}
            </Text>
          </View>
          {/* Table Title Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 0, flexShrink: 0, width: 190 },
              dimensions.width
            )}
          >
            {/* Column Title */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: 'theme.colors.custom_rgb16_16_16',
                  fontFamily: 'System',
                  fontSize: 14,
                  fontWeight: '600',
                  lineHeight: 14,
                  paddingBottom: 22,
                  paddingTop: 22,
                },
                dimensions.width
              )}
            >
              {'Last 30 days'}
            </Text>
          </View>
        </View>
      </View>

      <SimpleStyleScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        style={StyleSheet.applyWidth(
          {
            alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
            alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
            flex: { minWidth: Breakpoints.Laptop, value: 1 },
            width: { minWidth: Breakpoints.Laptop, value: '80%' },
          },
          dimensions.width
        )}
      >
        {/* Redeem */}
        <View>
          {/* Table Frame Negating Scroll View Horizontal Behavior */}
          <View>
            <ExampleDataApi.FetchGrabDataPointsGET>
              {({ loading, error, data, refetchGrabDataPoints }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <SimpleStyleFlatList
                    data={fetchData}
                    horizontal={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ??
                      listData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(listData)
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'T4rYVhfs'}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* First Row Table */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            {/* Data Point Frame */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderBottomWidth: 1,
                                  borderColor:
                                    'theme.colors.custom_rgb223_223_223',
                                  minWidth: 190,
                                  paddingRight: 44,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Collection Owner Frame */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    paddingBottom: 22,
                                    paddingTop: 22,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.text.strong,
                                      fontFamily: 'System',
                                      fontWeight: '600',
                                      marginRight: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'1.'}
                                </Text>
                                <CircleImage
                                  size={40}
                                  source={Images.JulianWanWNoLnJo7tS8Unsplash}
                                  style={StyleSheet.applyWidth(
                                    { marginRight: 8 },
                                    dimensions.width
                                  )}
                                />
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.text.strong,
                                      fontFamily: 'System',
                                      fontWeight: '600',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Jesica Jeez'}
                                </Text>
                              </View>
                            </View>
                            {/* Data Point Frame */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderBottomWidth: 1,
                                  borderColor:
                                    'theme.colors.custom_rgb223_223_223',
                                  flexGrow: 1,
                                  flexShrink: 0,
                                  maxWidth: 190,
                                  minWidth: 190,
                                  paddingRight: 44,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Volume Frame */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    flexGrow: 1,
                                    flexShrink: 0,
                                    paddingBottom: 22,
                                    paddingTop: 22,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Flex Attribute */}
                                <View>
                                  <Icon
                                    color={'theme.colors.custom_rgb183_183_183'}
                                    name={'MaterialCommunityIcons/ethereum'}
                                    size={18}
                                  />
                                </View>

                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.text.strong,
                                      fontFamily: 'System',
                                      fontWeight: '600',
                                      marginRight: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'133,800,22'}
                                </Text>
                              </View>
                            </View>
                            {/* Data Point Frame */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderBottomWidth: 1,
                                  borderColor:
                                    'theme.colors.custom_rgb223_223_223',
                                  flexGrow: 1,
                                  flexShrink: 0,
                                  maxWidth: 190,
                                  minWidth: 190,
                                  paddingRight: 44,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Data Frame */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    flexGrow: 1,
                                    flexShrink: 0,
                                    paddingBottom: 22,
                                    paddingTop: 22,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.text.strong,
                                      fontFamily: 'System',
                                      fontWeight: '600',
                                      marginRight: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'-2.73%'}
                                </Text>
                              </View>
                            </View>
                            {/* Data Point Frame */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderBottomWidth: 1,
                                  borderColor:
                                    'theme.colors.custom_rgb223_223_223',
                                  flexGrow: 1,
                                  flexShrink: 0,
                                  maxWidth: 190,
                                  minWidth: 190,
                                  paddingRight: 44,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Data Frame */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    flexGrow: 1,
                                    flexShrink: 0,
                                    paddingBottom: 22,
                                    paddingTop: 22,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.text.strong,
                                      fontFamily: 'System',
                                      fontWeight: '600',
                                      marginRight: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'+1430%'}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    inverted={false}
                    nestedScrollEnabled={true}
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: theme.colors.border.brand,
                        borderTopWidth: 1,
                        flex: 1,
                      },
                      dimensions.width
                    )}
                  />
                );
              }}
            </ExampleDataApi.FetchGrabDataPointsGET>
          </View>
        </View>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(RedeemVouchersScreen);
