import React from 'react';
import { Icon, Touchable, useTheme } from '@draftbit/ui';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { systemWeights } from 'react-native-typography';
import LinkingConfiguration from './LinkingConfiguration';
import * as AuthApi from './apis/AuthApi.js';
import * as GlobalVariables from './config/GlobalVariableContext';
import AdminScreen from './screens/AdminScreen';
import AdvertMonitorScreen from './screens/AdvertMonitorScreen';
import DashboardScreen from './screens/DashboardScreen';
import FeedbackCampaignsScreen from './screens/FeedbackCampaignsScreen';
import ImpulseAdvertRegistrationScreen from './screens/ImpulseAdvertRegistrationScreen';
import LandingPageScreen from './screens/LandingPageScreen';
import LoginScreen from './screens/LoginScreen';
import PortfolioScreen from './screens/PortfolioScreen';
import RedeemVouchersScreen from './screens/RedeemVouchersScreen';
import RoutingPageScreen from './screens/RoutingPageScreen';
import palettes from './themes/palettes';
import Breakpoints from './utils/Breakpoints';
import showAlertUtil from './utils/showAlert';
import useWindowDimensions from './utils/useWindowDimensions';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

function DefaultDrawerIcon({ tintColor, navigation }) {
  return (
    <Touchable
      onPress={() => navigation.toggleDrawer()}
      style={[styles.headerContainer, styles.headerContainerLeft]}
    >
      <Icon
        name="EvilIcons/navicon"
        size={27}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </Touchable>
  );
}

function Public() {
  const theme = useTheme();

  return (
    <Stack.Navigator
      initialRouteName="LandingPageScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="LoginScreen"
        component={LoginScreen}
        options={({ navigation }) => ({
          headerShown: false,
          title: 'Login',
        })}
      />
      <Stack.Screen
        name="ImpulseAdvertRegistrationScreen"
        component={ImpulseAdvertRegistrationScreen}
        options={({ navigation }) => ({
          headerShown: false,
          title: 'Impulse Advert Registration',
        })}
      />
      <Stack.Screen
        name="LandingPageScreen"
        component={LandingPageScreen}
        options={({ navigation }) => ({
          title: 'Landing Page',
        })}
      />
    </Stack.Navigator>
  );
}

function Advert() {
  const theme = useTheme();

  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const tabBarOrDrawerIcons = {
    AdvertMonitorScreen: '',
    RedeemVouchersScreen: '',
  };

  return (
    <Drawer.Navigator
      initialRouteName="AdvertMonitorScreen"
      screenOptions={({ navigation }) => ({
        headerLeft: ({ tintColor }) => (
          <DefaultDrawerIcon tintColor={tintColor} navigation={navigation} />
        ),
        headerRight: ({ tintColor }) => (
          <Touchable
            style={[styles.headerContainer, styles.headerContainerRight]}
            onPress={() => {
              try {
                setGlobalVariableValue({
                  key: 'AUTH_HEADER',
                  value: '',
                });
                if (navigation.canGoBack()) {
                  navigation.popToTop();
                }
                navigation.replace('Restricted', {
                  screen: 'RoutingPageScreen',
                });
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View style={styles.headerLabelWrapper}>
              <Text style={[styles.headerLabel]}>Logout</Text>
            </View>
            <Icon
              name="MaterialIcons/logout"
              size={Platform.OS === 'ios' ? 21 : 24}
              color={tintColor}
              style={[styles.headerIcon, styles.headerIconRight]}
            />
          </Touchable>
        ),
      })}
    >
      <Drawer.Screen
        name="AdvertMonitorScreen"
        component={AdvertMonitorScreen}
        options={({ navigation }) => ({
          title: 'Advert Monitor',
        })}
      />
      <Drawer.Screen
        name="RedeemVouchersScreen"
        component={RedeemVouchersScreen}
        options={({ navigation }) => ({
          title: 'Redeem Vouchers',
        })}
      />
    </Drawer.Navigator>
  );
}

function CreateAndAnalyze() {
  const theme = useTheme();

  const Constants = GlobalVariables.useValues();
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const tabBarOrDrawerIcons = {
    DashboardScreen: '',
    FeedbackCampaignsScreen: '',
    PortfolioScreen: '',
  };

  return (
    <Drawer.Navigator
      initialRouteName="DashboardScreen"
      screenOptions={({ navigation }) => ({
        headerLeft: ({ tintColor }) => (
          <DefaultDrawerIcon tintColor={tintColor} navigation={navigation} />
        ),
        headerRight: ({ tintColor }) => (
          <Touchable
            style={[styles.headerContainer, styles.headerContainerRight]}
            onPress={() => {
              try {
                setGlobalVariableValue({
                  key: 'AUTH_HEADER',
                  value: '',
                });
                navigation.navigate('Restricted', {
                  screen: 'RoutingPageScreen',
                });
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View style={styles.headerLabelWrapper}>
              <Text style={[styles.headerLabel]}>Logout</Text>
            </View>
            <Icon
              name="MaterialIcons/logout"
              size={Platform.OS === 'ios' ? 21 : 24}
              color={tintColor}
              style={[styles.headerIcon, styles.headerIconRight]}
            />
          </Touchable>
        ),
      })}
    >
      <Drawer.Screen
        name="DashboardScreen"
        component={DashboardScreen}
        options={({ navigation }) => ({
          title: 'Dashboard',
        })}
      />
      <Drawer.Screen
        name="FeedbackCampaignsScreen"
        component={FeedbackCampaignsScreen}
        options={({ navigation }) => ({
          title: 'Feedback Campaigns',
        })}
      />
      <Drawer.Screen
        name="PortfolioScreen"
        component={PortfolioScreen}
        options={({ navigation }) => ({
          title: 'Portfolio',
        })}
      />
    </Drawer.Navigator>
  );
}

function Restricted() {
  const theme = useTheme();

  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const tabBarOrDrawerIcons = {
    AdminScreen: '',
    CreateAndAnalyze: '',
    Advert: '',
    RoutingPageScreen: '',
  };

  return (
    <Drawer.Navigator
      initialRouteName="RoutingPageScreen"
      screenOptions={({ navigation }) => ({
        headerLeft: ({ tintColor }) => (
          <DefaultDrawerIcon tintColor={tintColor} navigation={navigation} />
        ),
      })}
    >
      <Drawer.Screen
        name="AdminScreen"
        component={AdminScreen}
        options={({ navigation }) => ({
          title: 'Admin',
        })}
      />
      <Drawer.Screen
        name="CreateAndAnalyze"
        component={CreateAndAnalyze}
        options={({ navigation }) => ({
          headerShown: false,
          title: 'Create & Analyze',
        })}
      />
      <Drawer.Screen
        name="Advert"
        component={Advert}
        options={({ navigation }) => ({
          headerShown: false,
          title: 'Advert',
        })}
      />
      <Drawer.Screen
        name="RoutingPageScreen"
        component={RoutingPageScreen}
        options={({ navigation }) => ({
          headerShown: false,
          title: 'Routing Page',
        })}
      />
    </Drawer.Navigator>
  );
}

export default function RootAppNavigator() {
  const theme = useTheme();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#FFFFFF',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="Public"
        screenOptions={({ navigation }) => ({
          cardStyle: { flex: 1 },
          headerBackImage:
            Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        })}
      >
        <Stack.Screen
          name="Public"
          component={Public}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Public',
          })}
        />
        <Stack.Screen
          name="Restricted"
          component={Restricted}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Restricted',
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerContainerRight: Platform.select({ ios: { marginRight: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
  headerIconRight: Platform.select({ ios: { marginLeft: 6 } }),
  headerLabel: { fontSize: 17, letterSpacing: 0.35 },
  headerLabelWrapper: { flexDirection: 'row', alignItems: 'flex-start' },
});
