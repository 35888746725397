import React from 'react';
import {
  Button,
  Icon,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleKeyboardAwareScrollView,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthApi from '../apis/AuthApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const FeedbackCampaignsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [textInputValue, setTextInputValue] = React.useState('');
  const formatCurrency = value => {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(value);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const CheckAuth = (await AuthApi.authMeGET(Constants))?.json;
        const role = CheckAuth?.role;
        console.log('Feedback Campaign: role check');
        if (role === 'admin' || 'analyst') {
          return;
        }
        console.log('Feedback Campaign: failed role check -> re-route');
        navigation.navigate('Restricted', { screen: 'RoutingPageScreen' });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.border.brand },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' } },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              flex: { minWidth: Breakpoints.Laptop, value: 1 },
              justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              margin: { minWidth: Breakpoints.Laptop, value: 5 },
            },
            dimensions.width
          )}
        >
          {/* Custom Code 4 */}
          <Utils.CustomCodeErrorBoundary>
            <CustomCode.ChatGPTComponent />
          </Utils.CustomCodeErrorBoundary>
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
              flex: { minWidth: Breakpoints.Laptop, value: 1 },
              margin: { minWidth: Breakpoints.Laptop, value: 5 },
            },
            dimensions.width
          )}
        >
          <SimpleStyleFlatList
            data={Constants['speciaities']}
            horizontal={false}
            inverted={false}
            keyExtractor={(listData, index) =>
              listData?.id ??
              listData?.uuid ??
              index?.toString() ??
              JSON.stringify(listData)
            }
            keyboardShouldPersistTaps={'never'}
            listKey={'37bpGbIF'}
            nestedScrollEnabled={false}
            numColumns={1}
            onEndReachedThreshold={0.5}
            renderItem={({ item, index }) => {
              const listData = item;
              return (
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'Lorem ipsum dolor sit amet'}
                </Text>
              );
            }}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(FeedbackCampaignsScreen);
