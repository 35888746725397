import React from 'react';
import { Spacer, withTheme } from '@draftbit/ui';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const FooterBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const handleLogin = (navigation, Variables, setGlobalVariableValue) => {
    //const username = Variables.username; // Retrieve the username screen variable
    //const password = Variables.password; // Retrieve the password screen variable

    if (username === 'admin' && password === 'hoXse1-jigcec-tatpiw') {
      setGlobalVariableValue({
        key: 'isLoggedIn',
        value: true,
      });

      navigation.navigate('MainDrawer'); // Navigate to the Drawer Navigator
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignContent: [
            { minWidth: Breakpoints.Laptop, value: 'center' },
            { minWidth: Breakpoints.Mobile, value: 'center' },
          ],
          alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
          alignSelf: { minWidth: Breakpoints.Laptop, value: 'stretch' },
          backgroundColor: [
            {
              minWidth: Breakpoints.Laptop,
              value: palettes.App['Custom Color 4'],
            },
            {
              minWidth: Breakpoints.Mobile,
              value: palettes.App['Custom Color 4'],
            },
          ],
          bottom: [
            { minWidth: Breakpoints.Laptop, value: 0 },
            { minWidth: Breakpoints.Mobile, value: 0 },
          ],
          flexDirection: { minWidth: Breakpoints.Laptop, value: 'column' },
          justifyContent: { minWidth: Breakpoints.Laptop, value: 'flex-end' },
          left: [
            { minWidth: Breakpoints.Laptop, value: 0 },
            { minWidth: Breakpoints.Mobile, value: 0 },
          ],
          marginTop: [
            { minWidth: Breakpoints.Tablet, value: 50 },
            { minWidth: Breakpoints.Laptop, value: 20 },
          ],
          position: [
            { minWidth: Breakpoints.Laptop, value: 'absolute' },
            { minWidth: Breakpoints.Mobile, value: 'absolute' },
          ],
          right: [
            { minWidth: Breakpoints.Laptop, value: 0 },
            { minWidth: Breakpoints.Mobile, value: 0 },
          ],
        },
        dimensions.width
      )}
    >
      {/* View 2 */}
      <View />
      <View>
        <Spacer left={8} right={8} bottom={8} top={8} />
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: [
                {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App['Custom Color'],
                },
                {
                  minWidth: Breakpoints.Mobile,
                  value: palettes.App['Custom Color'],
                },
              ],
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {'© 2024 by Impulse'}
        </Text>
        {/* Spacer 2 */}
        <Spacer bottom={8} left={8} right={8} top={8} />
      </View>
    </View>
  );
};

export default withTheme(FooterBlock);
