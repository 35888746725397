import React, { useEffect, useState } from 'react';
import {
  View,
  StyleSheet,
  ActivityIndicator,
  Text as RNText,
  Dimensions,
  ScrollView,
} from 'react-native';
import Svg, { Rect, Text } from 'react-native-svg';

// Fetch data from the API
const fetchHeatmapData = async () => {
  try {
    const response = await fetch(
      'https://x8ki-letl-twmt.n7.xano.io/api:1zczavK0/product_ratings',
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    );
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

// Group data by brand and categories
const groupDataByBrand = data => {
  const categories = [
    'Komfort',
    'Design',
    'Haltbarkeit',
    'Passform',
    'Material',
    'Wasserbestaendigkeit',
    'Reinigung',
    'Preis',
    'Atmungsaktivitaet',
  ];

  const groupedData = {};
  data.forEach(item => {
    if (!groupedData[item.Product_Brand]) {
      groupedData[item.Product_Brand] = {
        brand: item.Product_Brand,
        votes: categories.reduce((acc, category) => {
          acc[category] = [];
          return acc;
        }, {}),
      };
    }

    categories.forEach(category => {
      let value = item[category];
      if (typeof value === 'string') {
        value = value.toLowerCase() === 'true';
      } else {
        value = !!value;
      }
      groupedData[item.Product_Brand].votes[category].push(value);
    });
  });

  return Object.values(groupedData);
};

// Calculate the percentage of "like" votes
const calculateLikePercentage = votes => {
  const totalVotes = votes.length;
  const trueVotes = votes.filter(vote => {
    if (typeof vote === 'string') {
      return vote.toLowerCase() === 'true';
    }
    return !!vote;
  }).length;

  return totalVotes > 0 ? Math.round((trueVotes / totalVotes) * 100) : 0;
};

// Get color based on percentage
const getColorFromPercentage = percentage => {
  const red = Math.round((100 - percentage) * 2.55);
  const green = Math.round(percentage * 2.55);
  return `rgb(${red}, ${green}, 0)`;
};

// Main Heatmap Component
export function Heatmap() {
  const [likeOrNotGroups, setLikeOrNotGroups] = useState({});
  const [loading, setLoading] = useState(true);
  const { width: screenWidth } = Dimensions.get('window');
  const squareSize = 80;

  const categories = [
    'Komfort',
    'Design',
    'Haltbarkeit',
    'Passform',
    'Material',
    'Wasserbestaendigkeit',
    'Reinigung',
    'Preis',
    'Atmungsaktivitaet',
  ];

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchHeatmapData();

      const groupedByLikeOrNot = data.reduce((acc, item) => {
        const key =
          item.like_or_not === true
            ? 'liked their shoes'
            : item.like_or_not === false
            ? 'did not like their shoes'
            : 'unknown';

        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {});

      const processedGroups = {};
      Object.entries(groupedByLikeOrNot).forEach(([key, group]) => {
        const groupedData = groupDataByBrand(group);
        const processedData = groupedData.map(brandData => {
          const votesWithPercentages = {};
          Object.keys(brandData.votes).forEach(category => {
            const percentage = calculateLikePercentage(
              brandData.votes[category]
            );
            votesWithPercentages[category] = percentage;
          });
          return { brand: brandData.brand, votes: votesWithPercentages };
        });
        processedGroups[key] = processedData;
      });

      setLikeOrNotGroups(processedGroups);
      setLoading(false);
    };

    fetchData();
  }, []);

  // Loading state
  if (loading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  // Render Heatmap
  const renderHeatmap = (data, title) => {
    const heatmapWidth = squareSize * categories.length;
    const heatmapHeight = squareSize * data.length;

    return (
      <View key={title} style={styles.heatmapContainer}>
        <RNText style={styles.title}>{title}</RNText>
        <View style={styles.heatmapRow}>
          {/* Fixed Column for Brand Names */}
          <View style={styles.fixedColumn}>
            {data.map(item => (
              <RNText
                key={item.brand}
                style={[
                  styles.brandText,
                  { height: squareSize, lineHeight: squareSize },
                ]}
              >
                {item.brand}
              </RNText>
            ))}
          </View>
          {/* Scrollable Heatmap and X-Axis Labels */}
          <ScrollView horizontal>
            <View>
              {/* Heatmap SVG */}
              <Svg width={heatmapWidth} height={heatmapHeight}>
                {/* Render Heatmap Cells */}
                {data.map((item, rowIndex) =>
                  categories.map((category, colIndex) => {
                    const likePercentage = item.votes[category];
                    const fillColor = getColorFromPercentage(likePercentage);
                    return (
                      <React.Fragment key={`${item.brand}-${category}`}>
                        <Rect
                          x={colIndex * squareSize}
                          y={rowIndex * squareSize}
                          width={squareSize}
                          height={squareSize}
                          fill={fillColor}
                        />
                        <Text
                          x={colIndex * squareSize + squareSize / 2}
                          y={rowIndex * squareSize + squareSize / 2}
                          fontSize={12}
                          fill="#070707"
                          textAnchor="middle"
                          alignmentBaseline="middle"
                        >
                          {`${likePercentage}%`}
                        </Text>
                      </React.Fragment>
                    );
                  })
                )}
              </Svg>
              {/* X-Axis Labels */}
              <View style={styles.xAxisLabelContainer}>
                {categories.map((category, colIndex) => (
                  <View
                    key={category}
                    style={[
                      styles.xAxisLabel,
                      { width: squareSize, height: 60 },
                    ]}
                  >
                    <RNText
                      style={styles.xAxisLabelText}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      {category}
                    </RNText>
                  </View>
                ))}
              </View>
            </View>
          </ScrollView>
        </View>
        {/* Vertical Legend */}
        <View style={styles.legendContainer}>
          <RNText style={styles.legendTitle}>Legend</RNText>
          <View style={styles.legendItem}>
            <View
              style={[
                styles.legendColor,
                { backgroundColor: getColorFromPercentage(0) },
              ]}
            />
            <RNText style={styles.legendLabel}>0%</RNText>
          </View>
          <View style={styles.legendItem}>
            <View
              style={[
                styles.legendColor,
                { backgroundColor: getColorFromPercentage(50) },
              ]}
            />
            <RNText style={styles.legendLabel}>50%</RNText>
          </View>
          <View style={styles.legendItem}>
            <View
              style={[
                styles.legendColor,
                { backgroundColor: getColorFromPercentage(100) },
              ]}
            />
            <RNText style={styles.legendLabel}>100%</RNText>
          </View>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {Object.entries(likeOrNotGroups).map(([groupKey, groupData]) =>
        renderHeatmap(groupData, `Group: ${groupKey}`)
      )}
    </View>
  );
}

// Styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
    backgroundColor: '#f9f9f9',
  },
  heatmapContainer: {
    flex: 1,
    backgroundColor: '#fff',
    paddingVertical: 20,
    paddingHorizontal: 10,
    marginRight: 10,
    overflow: 'hidden',
  },
  heatmapRow: {
    flexDirection: 'row',
  },
  fixedColumn: {
    marginRight: 10,
    justifyContent: 'center',
    flexDirection: 'column',
  },
  brandText: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    overflow: 'hidden',
    wordWrap: 'break-word',
    height: 80,
    lineHeight: 80,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  },
  xAxisLabelContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  xAxisLabel: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 2,
  },
  xAxisLabelText: {
    fontSize: 12,
    textAlign: 'center',
    width: 80,
  },
  legendContainer: {
    position: 'absolute',
    top: 20,
    right: 10,
    padding: 15,
    backgroundColor: '#fff',
    borderRadius: 8,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  legendTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  legendColor: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  legendLabel: {
    fontSize: 14,
    color: '#333',
  },
});
