import React from 'react';
import {
  Link,
  ScreenContainer,
  SimpleStyleScrollView,
  Spacer,
  withTheme,
} from '@draftbit/ui';
import * as WebBrowser from 'expo-web-browser';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import FooterBlock from '../components/FooterBlock';
import MenuBlock from '../components/MenuBlock';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import * as LandingPage from '../custom-files/LandingPage';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const LandingPageScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer hasSafeArea={false} scrollable={true}>
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: {
              minWidth: Breakpoints.Laptop,
              value: palettes.App['Custom Color 4'],
            },
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App['Custom Color 4'],
              },
              flex: { minWidth: Breakpoints.Laptop, value: 1 },
            },
            dimensions.width
          )}
        >
          <MenuBlock />
        </View>
        {/* Main */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: { minWidth: Breakpoints.Laptop, value: 'column' },
            },
            dimensions.width
          )}
        >
          <SimpleStyleScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
          >
            <Utils.CustomCodeErrorBoundary>
              <LandingPage.LandingPage />
            </Utils.CustomCodeErrorBoundary>
          </SimpleStyleScrollView>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'stretch' },
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App['Custom Color'],
                },
                flex: { minWidth: Breakpoints.Laptop, value: 1 },
                height: { minWidth: Breakpoints.Laptop, value: 100 },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                },
                dimensions.width
              )}
            >
              <Spacer left={8} right={8} bottom={15} top={10} />
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                      fontFamily: [
                        { minWidth: Breakpoints.Laptop, value: 'System' },
                        { minWidth: Breakpoints.Mobile, value: 'System' },
                      ],
                      fontSize: [
                        { minWidth: Breakpoints.Laptop, value: 35 },
                        { minWidth: Breakpoints.Mobile, value: 20 },
                      ],
                      fontWeight: [
                        { minWidth: Breakpoints.Laptop, value: '700' },
                        { minWidth: Breakpoints.Mobile, value: '700' },
                      ],
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Impulse Advert Partners'}
              </Text>
              {/* Spacer 2 */}
              <Spacer bottom={8} left={8} right={8} top={8} />
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignSelf: 'center',
                  flex: [
                    { minWidth: Breakpoints.Laptop, value: 1 },
                    { minWidth: Breakpoints.Mobile, value: 1 },
                  ],
                },
                dimensions.width
              )}
            >
              <Spacer bottom={8} left={8} right={8} top={8} />
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: [
                      { minWidth: Breakpoints.Laptop, value: 'center' },
                      { minWidth: Breakpoints.Mobile, value: 'center' },
                    ],
                    alignSelf: 'center',
                    flex: [
                      { minWidth: Breakpoints.Laptop, value: 1 },
                      { minWidth: Breakpoints.Mobile, value: 1 },
                    ],
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['logodasmarktchen'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        height: { minWidth: Breakpoints.Laptop, value: 150 },
                        width: { minWidth: Breakpoints.Laptop, value: 150 },
                      }
                    ),
                    dimensions.width
                  )}
                />
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'center',
                      width: { minWidth: Breakpoints.Laptop, value: 200 },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          alignSelf: {
                            minWidth: Breakpoints.Laptop,
                            value: 'center',
                          },
                          color: [
                            {
                              minWidth: Breakpoints.Laptop,
                              value: palettes.App['Custom Color 5'],
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: palettes.App['Custom Color 7'],
                            },
                          ],
                          fontFamily: [
                            { minWidth: Breakpoints.Laptop, value: 'System' },
                            { minWidth: Breakpoints.Mobile, value: 'System' },
                          ],
                          fontSize: { minWidth: Breakpoints.Laptop, value: 20 },
                          fontWeight: [
                            { minWidth: Breakpoints.Laptop, value: '700' },
                            { minWidth: Breakpoints.Mobile, value: '700' },
                          ],
                          marginBottom: {
                            minWidth: Breakpoints.Laptop,
                            value: 3,
                          },
                          marginTop: [
                            { minWidth: Breakpoints.Laptop, value: 5 },
                            { minWidth: Breakpoints.Mobile, value: 5 },
                          ],
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Das Märktchen\n'}
                  </Text>

                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          alignSelf: {
                            minWidth: Breakpoints.Laptop,
                            value: 'center',
                          },
                          color: {
                            minWidth: Breakpoints.Laptop,
                            value: palettes.App['Custom Color 4'],
                          },
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'System',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                          fontWeight: {
                            minWidth: Breakpoints.Laptop,
                            value: '300',
                          },
                          textAlign: [
                            { minWidth: Breakpoints.Laptop, value: 'left' },
                            { minWidth: Breakpoints.Mobile, value: 'center' },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Seckenheimer Straße 16'}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          alignSelf: {
                            minWidth: Breakpoints.Laptop,
                            value: 'center',
                          },
                          color: {
                            minWidth: Breakpoints.Laptop,
                            value: palettes.App['Custom Color 4'],
                          },
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'System',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                          fontWeight: {
                            minWidth: Breakpoints.Laptop,
                            value: '300',
                          },
                          textAlign: [
                            { minWidth: Breakpoints.Laptop, value: 'left' },
                            { minWidth: Breakpoints.Mobile, value: 'center' },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'68165 Mannheim'}
                  </Text>
                  <Link
                    accessible={true}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            'https://dasmaerktchen.de/'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    selectable={false}
                    {...GlobalStyles.LinkStyles(theme)['Link'].props}
                    selectionColor={StyleSheet.getWidthValue(
                      [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App['Custom Color 4'],
                        },
                      ],
                      dimensions.width
                    )}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinkStyles(theme)['Link'].style,
                        {
                          alignSelf: {
                            minWidth: Breakpoints.Laptop,
                            value: 'center',
                          },
                          color: [
                            {
                              minWidth: Breakpoints.Laptop,
                              value: palettes.App['Custom Color 4'],
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: palettes.App['Custom Color 4'],
                            },
                          ],
                          flex: { minWidth: Breakpoints.Laptop, value: 1 },
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'System',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
                          fontWeight: {
                            minWidth: Breakpoints.Laptop,
                            value: '300',
                          },
                          textAlign: [
                            { minWidth: Breakpoints.Laptop, value: 'left' },
                            { minWidth: Breakpoints.Mobile, value: 'center' },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                    title={'dasmaerktchen.de'}
                  />
                </View>
              </View>
              {/* Spacer 2 */}
              <Spacer left={8} right={8} bottom={10} top={30} />
            </View>
          </View>
          <Spacer left={8} right={8} bottom={15} top={15} />
        </View>
      </View>
      {/* Footer 2 */}
      <FooterBlock />
    </ScreenContainer>
  );
};

export default withTheme(LandingPageScreen);
