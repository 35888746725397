import React from 'react';
import {
  Button,
  ScreenContainer,
  Spacer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as AuthApi from '../apis/AuthApi.js';
import FooterBlock from '../components/FooterBlock';
import MenuBlock from '../components/MenuBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const LoginScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [emailInputValue, setEmailInputValue] = React.useState('');
  const [multiSelectPicker2Value, setMultiSelectPicker2Value] = React.useState(
    []
  );
  const [passwordInputValue, setPasswordInputValue] = React.useState('');
  const handleLogin = (navigation, Variables, setGlobalVariableValue) => {
    //const username = Variables.username; // Retrieve the username screen variable
    //const password = Variables.password; // Retrieve the password screen variable

    if (username === 'admin' && password === 'hoXse1-jigcec-tatpiw') {
      setGlobalVariableValue({
        key: 'isLoggedIn',
        value: true,
      });

      navigation.navigate('MainDrawer'); // Navigate to the Drawer Navigator
    } else {
      alert('Invalid credentials');
    }
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const Check_Auth = (await AuthApi.authMeGET(Constants))?.json;
        const email = Check_Auth?.email;
        const role = Check_Auth?.role;
        if (!email) {
          return;
        }
        if (!role) {
          return;
        }
        navigation.navigate('Restricted', { screen: 'RoutingPageScreen' });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Laptop,
            value: palettes.App['Custom Color 4'],
          },
        },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: {
              minWidth: Breakpoints.Laptop,
              value: palettes.App['Custom Color 4'],
            },
          },
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              flex: { minWidth: Breakpoints.Laptop, value: 1 },
            },
            dimensions.width
          )}
        >
          <MenuBlock />
        </View>
        {/* Top */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
        >
          {/* Title */}
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                color: {
                  minWidth: Breakpoints.Laptop,
                  value: theme.colors.background.brand,
                },
                fontFamily: [
                  { minWidth: Breakpoints.Mobile, value: 'System' },
                  { minWidth: Breakpoints.Laptop, value: 'System' },
                ],
                fontSize: [
                  { minWidth: Breakpoints.Mobile, value: 36 },
                  { minWidth: Breakpoints.Laptop, value: 60 },
                ],
                fontWeight: [
                  { minWidth: Breakpoints.Mobile, value: '600' },
                  { minWidth: Breakpoints.Laptop, value: '700' },
                ],
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Impulse'}
          </Text>
          {/* Subtitle */}
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                color: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: theme.colors.text.strong,
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: theme.colors.background.brand,
                  },
                ],
                fontFamily: [
                  { minWidth: Breakpoints.Mobile, value: 'System' },
                  { minWidth: Breakpoints.Laptop, value: 'System' },
                ],
                fontSize: { minWidth: Breakpoints.Laptop, value: 25 },
                fontWeight: [
                  { minWidth: Breakpoints.Mobile, value: '400' },
                  { minWidth: Breakpoints.Laptop, value: '200' },
                ],
                marginTop: 4,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Supply Chain Feedback'}
          </Text>
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
              width: { minWidth: Breakpoints.Laptop, value: '100%' },
            },
            dimensions.width
          )}
        >
          {/* Main */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App['Custom Color'],
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 2 },
                margin: { minWidth: Breakpoints.Laptop, value: 40 },
                opacity: { minWidth: Breakpoints.Laptop, value: 1 },
                width: { minWidth: Breakpoints.Laptop, value: '80%' },
              },
              dimensions.width
            )}
          >
            {/* Login Form */}
            <View
              style={StyleSheet.applyWidth(
                { marginTop: 24, paddingLeft: 36, paddingRight: 36 },
                dimensions.width
              )}
            >
              {/* Error Message */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.background.danger,
                    fontSize: 12,
                    marginBottom: 16,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {null}
              </Text>
              {/* Email Input */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newEmailInputValue => {
                  try {
                    /* 'Set Variable' action requires configuration: choose a variable */
                    setEmailInputValue(newEmailInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                keyboardType={'email-address'}
                placeholder={'E-Mail' ?? 'Email'}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                    {
                      borderColor: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App['Custom Color 4'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App['Custom Color 4'],
                        },
                      ],
                      borderRadius: 2,
                      color: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App['Custom Color 4'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App['Custom Color 4'],
                        },
                      ],
                      fontFamily: 'System',
                      fontWeight: '400',
                      paddingBottom: 16,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 16,
                    }
                  ),
                  dimensions.width
                )}
                textContentType={'emailAddress'}
                value={emailInputValue}
              />
              <Spacer left={8} right={8} bottom={12} top={12} />
              {/* Password Input */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newPasswordInputValue => {
                  try {
                    /* 'Set Variable' action requires configuration: choose a variable */
                    setPasswordInputValue(newPasswordInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                placeholder={'Passwort' ?? 'Password'}
                secureTextEntry={true}
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    borderLeftWidth: 1,
                    borderRadius: 2,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    color: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    fontFamily: 'System',
                    fontWeight: '400',
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                value={passwordInputValue}
              />
              <Spacer left={8} right={8} bottom={24} top={24} />
              {/* Sign In Button */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const Execute_Login = (
                        await AuthApi.loginPOST(Constants, {
                          signupEmail: emailInputValue,
                          signupPassword: passwordInputValue,
                        })
                      )?.json;
                      const authToken = Execute_Login?.authToken;
                      const errorMessage = Execute_Login?.message;
                      setGlobalVariableValue({
                        key: 'ERROR_MESSAGE',
                        value: errorMessage,
                      });
                      if (!authToken) {
                        return;
                      }
                      setGlobalVariableValue({
                        key: 'AUTH_HEADER',
                        value: 'Bearer ' + authToken,
                      });
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('Restricted', {
                        screen: 'RoutingPageScreen',
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Custom Color 4'],
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Custom Color 4'],
                      },
                    ],
                    borderRadius: 2,
                    fontFamily: 'System',
                    fontWeight: '700',
                    paddingBottom: 16,
                    paddingTop: 16,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Anmelden'}
              >
                {'Sign Up'}
              </Button>
              <Spacer left={8} right={8} bottom={16} top={16} />
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {Constants['ERROR_MESSAGE']}
              </Text>
            </View>
          </View>
          <Spacer left={8} right={8} />
        </View>
      </View>
      <FooterBlock />
    </ScreenContainer>
  );
};

export default withTheme(LoginScreen);
